<script lang="ts" setup>
  import { useRouter } from "vue-router"
  import {
    reset as resetForm
  } from "@formkit/core"

  import { gettext } from "@/utils/Translation"
  import { renewSession, checkPin } from "@/utils/Session"
  import { showFlashMessage } from "@/utils/GlobalState"
  import { logout } from "@/services/Account"

  const { $gettext } = gettext
  const router = useRouter()
  const schema = [
    {
      $formkit: "text",
      name: "pin",
      id: "$pin",
      label: $gettext("Code"),
      help: $gettext("Only lowercase letters and numbers."),
      minlength: 4,
      maxlength: 4,
      validation: "required",
      autocorrect: "off",
      autocapitalize: "off",
    }
  ]

  function submitForm({ pin }) {
    let success = false
    try {
      success = checkPin(pin)
    }
    catch(error) {
      if (error.message === "TOO_MANY_TRIES") {
        showFlashMessage({ msg: $gettext("Too many tries. Please log in again."), class: "is-warning" })
        logout(true)  // async, but we don't care about the return value
        router.push({ name: "Login" })
      }
      else {
        console.log(error)
      }
      return
    }
    if (success) {
      showFlashMessage({ msg: $gettext("Your code has been confirmed."), class: "is-success" })
      renewSession()
      // Don't go back if there's no back–that manifests as
      // the browser's home page or something.
      if (window.history.state?.back) {
        router.back()
      }
      else {
        router.replace({ name: "SiteList" })
      }
    }
    else {
      showFlashMessage({ msg: $gettext("Your code was incorrect. Please try again."), class: "is-warning" })
      resetForm("pin-form")
    }
  }
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Confirm your entry code") }}
    </h1>
    <p>
      <strong>
        {{ $gettext("Please confirm your 4-letter code. ") }}
      </strong>
    </p>

    <FormKit
      type="form"
      id="pin-form"
      autocomplete="off"
      :submit-label="$gettext('Confirm')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema :schema="schema" />
    </FormKit>
  </article>
</template>
<style lang="scss"></style>
