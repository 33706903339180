<script lang="ts" setup>
  import { useRouter } from "vue-router"
  import {
    reset as resetForm,
    setErrors as setFormErrors
  } from "@formkit/core"

  import { gettext } from "@/utils/Translation"
  import { setPin, getSessionStatus } from "@/utils/Session"
  import { showFlashMessage } from "@/utils/GlobalState"

  const { $gettext } = gettext
  const router = useRouter()
  const schema = [
    {
      $formkit: "text",
      name: "pin",
      id: "$pin",
      label: $gettext("Code"),
      help: $gettext("Only lowercase letters and numbers."),
      minlength: 4,
      maxlength: 4,
      validation: "required",
      autocorrect: "off",
      autocapitalize: "off",
      // length and regex match validation didn't work for some reason.
      // validation: "length:4,4|matches:/[a-z0-9]/",
      // "validation-visibility": "live",
      // "validation-messages": {
      //   matches: $gettext("Code can only contain lowercase letters and numbers."),
      //   length: $gettext("Code must be exactly four letters long."),
      // },
    },
    {
      $formkit: "text",
      name: "pin_confirm",
      id: "$pin_confirm",
      label: $gettext("Confirm code"),
      autocorrect: "off",
      autocapitalize: "off",
      validation: "required|confirm",
      "validation-messages": {
        confirm: $gettext("Codes do not match."),
        required: $gettext("Please confirm your code."),
      },
    },
  ]

  function submitForm({ pin }) {
    try {
      setPin(pin)
    }
    catch(error) {
      let msg: string
      if (error.message === "TOO_SIMPLE") {
        msg = $gettext("That code is too common. Please try a different one.")
      }
      else if (error.message === "SAME_CHAR") {
        msg = $gettext("Your code must contain at least two different letters. Please try a different one.")
      }
      resetForm("pin-form")
      setFormErrors("pin-form", msg)
      return
    }
    showFlashMessage({ msg: $gettext("Your code has been set. Please remember it!"), class: "is-success" })
    router.push({ name: "HomeRedirect" })
  }

  // Check to make sure they're not circumventing the confirm form by trying to set a new PIN!
  if (getSessionStatus() === "e") {
    router.push({ name: "ReenterPinForm" })
  }
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Set an entry code") }}
    </h1>
    <p>
      <strong>
        {{ $gettext("To keep sensitive data protected in case your device is stolen or lost, please set a 4-letter code. ") }}
      </strong>
      {{ $gettext("You will be asked for this code any time you resume using the app. When you log out, this code will be erased and you will need to set a new one the next time you log in.") }}
    </p>

    <FormKit
      type="form"
      id="pin-form"
      autocomplete="off"
      :submit-label="$gettext('Save')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema :schema="schema" />
    </FormKit>
  </article>
</template>
<style lang="scss">
  /* Not scoped bc the form is rendered by formkit. Maybe this can be pulled
  out and used broadly? Haven't tested.
  */
  form#pin-form > .formkit-messages {
    padding-inline: 1em;
    margin-top: 0;
  }
</style>
