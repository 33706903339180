<script setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { setCurrentViewContext } from "@/utils/GlobalState"
import { getAccount, updateAccount } from "@/services/Account"
import { getAccountInfoSchema } from "@/schemas/Account"
import { getSiteByCmiId } from "@/services/Site"
import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

const route = useRoute()
const router = useRouter()
const siteCmiId = parseInt(route.params?.siteCmiId)
const accountCmiId = parseInt(route.params?.accountCmiId)
const site = ref({})
const successRoute = siteCmiId ? { name: "SiteHome", params: { siteCmiId } } : { name: "SiteList" }
const getFormData = async () => getAccount(siteCmiId, accountCmiId)
const uploadFunc = async (account) => updateAccount(account, siteCmiId, accountCmiId)

async function getData() {
  // Scenario: edit another's info–get the site first
  if (siteCmiId) {
    site.value = await getSiteByCmiId(siteCmiId)
    if (!site.value) {
      router.replace({ name: "SiteList" })
    }
    setCurrentViewContext(site, "site")
  }
  else {
    setCurrentViewContext()
  }
}

getData()
</script>

<template>
  <article>
    <OnlineRequiredForm
      :page-title="$gettext('Edit Account')"
      :schema="getAccountInfoSchema()"
      :form-data-retrieval-func="getFormData"
      :upload-func="uploadFunc"
      :success-route="successRoute"
    />
  </article>
</template>
