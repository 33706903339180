import { changeLocale as changeFormkitLocale } from '@formkit/vue'
import { formatLocale } from "d3-format"
import { timeFormatLocale } from "d3-time-format"

// Used by D3 to represent dates, times, & number formats appropriately in
// other languages/cultures. Curated manually based mostly on the templates
// available at https://unpkg.com/d3-time-format@2/
import { LOCALES } from "@/utils/D3Locales"
import { useLanguagePreferenceStore } from "@/utils/GlobalState"
import { gettext, updateLanguage } from "@/utils/Translation"

/*
 * Look up the current language setting from LocalStorage. If not set, infer one
 * and save to LocalStorage. Then update vue3-gettext and FormKit to use that lang.
 */
export async function setCurrentLanguage() {
  const languagePreference = useLanguagePreferenceStore()
  if (!languagePreference.value) {
    languagePreference.value = inferLanguage()
  }
  await updateLanguage(languagePreference.value)
  changeFormkitLocale(languagePreference.value)
}

/*
 * Inspect the browser's stated language preference, and see if it lines up with any
 * available translation; if so, return that language code. Otherwise, just return
 * the vue3-gettext default (en).
 * @return {string} - language code
 */
function inferLanguage() {
  if (!navigator.languages?.length) {
    return gettext.defaultLanguage
  }
  for (let i = 0; i < navigator.languages.length; i++) {
    try {
      const locale = new Intl.Locale(navigator.languages[i])
      if (locale.language in gettext.available) {
        return locale.language
      }
    }
    catch {
      continue
    }
  }
  return gettext.defaultLanguage
}

/*
 * Given a language code, update the locally persisted value and then
 * update what vue3-gettext is using for the interface.
 * @args {string} language code
 */
export async function updateLanguagePreference(lang: string) {
  const languagePreference = useLanguagePreferenceStore()
  languagePreference.value = lang
  await setCurrentLanguage()
}

export function getD3Locales() {
  const languagePreference = useLanguagePreferenceStore()
  if (!languagePreference.value) {
    languagePreference.value = inferLanguage()
  }
  const timeLocale = timeFormatLocale(LOCALES[languagePreference] || LOCALES.en)
  const numLocale = formatLocale(LOCALES[languagePreference] || LOCALES.en)
  return { timeLocale, numLocale }
}
