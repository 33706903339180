<script setup>
  import { useRouter } from "vue-router"

  const router = useRouter()

  function goBack() {
    return router.back()
  }
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("An internet connection is required for this page") }}
    </h1>
    <p>
      {{ $gettext("Your device thinks that you are not currently connected to the internet. Please check your connection and try again by revisiting this page.") }}
    </p>
  
    <button
      type="button"
      class="button is-fullwidth is-large is-responsive is-primary"
      @click="goBack"
    >
      {{ $gettext("Go back") }}
    </button>
    <div class="notification is-info">
      <!-- XXX: add a reference to a help page discussing online and offline considerations, once it exists. -->
      <p
        style="margin-bottom: 0"
        v-html="$gettext(
          'Many functions in <em>Count Me In</em> may be done offline. However, certain forms or pages require that you be online.',
          true,
        )"
      />
    </div>
  </article>
</template>
<style scoped>
  .notification {
    margin-top: 2em;
  }
</style>