<script setup>
import { useRouter } from "vue-router"

import { updateLanguagePreference } from "@/services/Translation"
import { gettext } from "@/utils/Translation"
import { showFlashMessage, setCurrentViewContext } from "@/utils/GlobalState"

const { $gettext } = gettext
const router = useRouter()
const currentLanguage = gettext.current
const niceCurrentLanguage = gettext.available[currentLanguage] || currentLanguage
const schema = [
  {
    $formkit: "select",
    name: "preferredLanguage",
    label: $gettext("Preferred Language"),
    value: "$preferredLanguage",
    help: $gettext("Current language is %{ currentLanguage }.", {
      currentLanguage: niceCurrentLanguage,
    }),
    options: gettext.available,
    "inner-class": "select",
  },
]

setCurrentViewContext()

function submitForm(data) {
  updateLanguagePreference(data.preferredLanguage)
    .then(() => {
      showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
      router.push({ name: "HomeRedirect" })
    })
}
</script>

<template>
  <article>
    <FormKit
      type="form"
      :submit-label="$gettext('Save')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema
        :schema="schema"
        :data="{ preferredLanguage: currentLanguage }"
      />
    </FormKit>
  </article>
</template>
